/* General Page Styling */
.user-management-page {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

h1 {
    text-align: center;
    color: #4a90e2;
    margin-bottom: 20px;
}

/* Controls */
.controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.search-input {
    padding: 10px;
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add-user-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.add-user-button:hover {
    background-color: #45a049;
}

/* Table Styling */
.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.user-table th,
.user-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.user-table th {
    background-color: #f7f7f7;
}

.user-table tr:hover {
    background-color: #f9f9f9;
}

/* Action Buttons */
.user-table button {
    margin-right: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}

.user-table button.edit {
    background-color: #f0ad4e;
    color: white;
}

.user-table button.edit:hover {
    background-color: #ec971f;
}

.user-table button.activate {
    background-color: #5cb85c;
    color: white;
}

.user-table button.activate:hover {
    background-color: #4cae4c;
}

.user-table button.delete {
    background-color: #d9534f;
    color: white;
}

.user-table button.delete:hover {
    background-color: #c9302c;
}

/* Status Badge */
.status-badge {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
}

.status-badge.active {
    background-color: #5cb85c;
    color: white;
}

.status-badge.inactive {
    background-color: #d9534f;
    color: white;
}

/* Pagination */
.pagination {
    text-align: center;
    margin-top: 20px;
    display: flex; /* Use flexbox to align items horizontally */
    justify-content: center; /* Center the buttons horizontally */
    gap: 5px; /* Add spacing between buttons */
}

.pagination button {
    padding: 5px 10px;
    border: none;
    background-color: #4a90e2;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button.active {
    background-color: #357abd;
}

.pagination button:hover {
    background-color: #357abd;
}
