.blog-page {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
}

.blog-title {
    text-align: center;
    font-size: 2.5rem;
    color: #222;
    margin-bottom: 40px;
}

.blog-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.blog-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.blog-card-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.blog-card-content {
    padding: 20px;
}

.blog-card-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.blog-card-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
}

.blog-card-link {
    text-decoration: none;
    color: #007BFF;
    font-weight: bold;
}

.blog-card-link:hover {
    text-decoration: underline;
}
