/* Navbar Container */
.navbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 80px;
}

/* Logo */
.navbar-logo {
    display: flex;
    align-items: center;
}

.navbar-logo .logo-image {
    height: 200px; /* Increased logo height */
    max-height: none;
    width: auto;
    object-fit: contain;
}

/* Navigation Links */
.navbar-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Align links to the right */
    width: 100%;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 15px;
    margin-right: 0; /* Removed margin-right */
}

.nav-links li {
    list-style: none;
}

.nav-links a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    cursor: pointer;
}

.nav-links a:hover {
    color: #ffbf00;
}

/* Action Buttons (Sign In/Sign Up) */
.nav-actions {
    display: flex;
    gap: 1rem;
    margin-left: 2rem; /* Added margin-left */
}

.login-button,
.signup-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.login-button {
    background: transparent;
    color: #333;
}

.signup-button {
    background: #ffbf00;
    color: white;
}

/* Hamburger Menu for Mobile */
.hamburger {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}

/* Responsive Navbar */
@media (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .navbar-nav {
        flex-direction: column;
        position: absolute;
        top: 80px;
        right: 0;
        background: white;
        width: 100%;
        display: none;
    }

    .navbar-nav.nav-open {
        display: flex;
    }

    .nav-links {
        flex-direction: column;
        gap: 1rem;
        margin-right: 0;
    }

    .nav-actions {
        margin-left: 0;
        justify-content: flex-end;
        width: 100%;
    }
}