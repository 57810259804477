/* Smooth scrolling */
html {
    scroll-behavior: smooth;
    scroll-padding-top: 80px; /* Adjust based on your header height */
}

/* Section animation styles */
.home > section {
    opacity: 0; /* Hidden by default */
    transform: translateY(20px); /* Initial position for animation */
    transition: opacity 0.6s ease, transform 0.6s ease; /* Animation duration and easing */
}

.home > section.visible {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Reset position after animation */
}
