.compliance-alert-page {
    padding: 20px;
}

.filters-section {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    align-items: flex-end;
}

.filter-group {
    display: flex;
    flex-direction: column;
}

.filter-group label {
    font-weight: bold;
    margin-bottom: 5px;
}

button {
    padding: 10px 15px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.alerts-section {
    margin-top: 20px;
}

.alerts-table {
    width: 100%;
    border-collapse: collapse;
}

.alerts-table th,
.alerts-table td {
    border: 1px solid #ddd;
    padding: 10px;
}

.alerts-table th {
    background-color: #f4f4f4;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 20px;
}
