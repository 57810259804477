.blog-post {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.8;
}

.blog-post-title {
    text-align: center;
    font-size: 2.5rem;
    color: #222;
    margin-bottom: 20px;
    margin-top: 60px; /* Add margin to ensure spacing below the Navbar */
}

.blog-post-date {
    text-align: center;
    font-size: 1rem;
    color: #777;
    margin-bottom: 30px;
}


.blog-post-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
    object-fit: cover;
}


.blog-post-content h2,
.blog-post-content h3 {
    margin: 20px 0 10px;
    color: #333;
    font-weight: bold;
}

.blog-post-content p {
    margin-bottom: 20px;
}

.blog-post-content ul {
    margin: 20px 0;
    padding-left: 40px;
    list-style-type: disc;
}
