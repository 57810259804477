/* Features Section */
.features-section {
    padding: 80px 20px;
    background: linear-gradient(to bottom right, #ffffff, #f5f5f5); /* Subtle gradient */
    color: #333;
    text-align: center;
}

.features-content {
    max-width: 1200px;
    margin: 0 auto;
}

.features-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.section-description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
    line-height: 1.8;
}

/* Feature Cards */
.feature-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
    gap: 20px;
}

.feature-card {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

.feature-card:hover {
    transform: translateY(-10px); /* Pop-up effect */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.feature-card h3 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
}

.feature-card p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
    .features-title {
        font-size: 2rem;
    }

    .section-description {
        font-size: 1rem;
    }

    .feature-card h3 {
        font-size: 1.2rem;
    }

    .feature-card p {
        font-size: 0.9rem;
    }
}
.disclaimer {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #888; /* Muted color for subtlety */
    text-align: center;
    max-width: 500px; /* Adjust width if needed */
    line-height: 1.4;
}
