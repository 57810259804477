/* Main Container */
.search-page {
    padding: 20px;
    background: #fdfdfd;
    color: #333;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
}

body.dark-mode .search-page {
    background: #121212;
    color: #e0e0e0;
}

/* Header Section */
.search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.search-header h1 {
    font-size: 1.5rem;
    color: #4a90e2;
}

.search-header .stats {
    display: flex;
    gap: 20px;
}

.stat-item {
    background: #ffffff;
    border-radius: 8px;
    padding: 10px 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

body.dark-mode .stat-item {
    background: #1e1e1e;
    box-shadow: none;
}

.stat-item:hover {
    transform: translateY(-3px);
}

.stat-item h3 {
    font-size: 1rem;
    color: #666;
}

body.dark-mode .stat-item h3 {
    color: #aaa;
}

.stat-item p {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

body.dark-mode .stat-item p {
    color: #e0e0e0;
}

/* Filters Section */
.filters-section {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

body.dark-mode .filters-section {
    background: #1e1e1e;
    box-shadow: none;
}

.filter-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

body.dark-mode .filter-group label {
    color: #e0e0e0;
}

.filter-group input,
.filter-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
    color: #333;
}

body.dark-mode .filter-group input,
body.dark-mode .filter-group select {
    background: #333333;
    color: #ffffff;
    border: 1px solid #555555;
}

.filter-buttons {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.filter-buttons button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.filter-buttons button:first-of-type {
    background: #4a90e2;
    color: #ffffff;
}

.filter-buttons button:first-of-type:hover {
    background: #357abd;
}

.filter-buttons button:last-of-type {
    background: #ffb84d;
    color: #ffffff;
}

.filter-buttons button:last-of-type:hover {
    background: #e39a33;
}

/* Results Section */
.results-section {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

body.dark-mode .results-section {
    background: #1e1e1e;
    box-shadow: none;
}

.results-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.results-table th,
.results-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #eee;
    color: #333;
}

body.dark-mode .results-table th,
body.dark-mode .results-table td {
    border-bottom: 1px solid #444;
    color: #e0e0e0;
}

.results-table th {
    background: #f7f7f7;
    font-weight: bold;
}

body.dark-mode .results-table th {
    background: #2c2c2c;
}

.results-table tr:nth-child(even) {
    background: #f9f9f9;
}

body.dark-mode .results-table tr:nth-child(even) {
    background: #252525;
}

.results-table a {
    color: #4a90e2;
    text-decoration: none;
}

.results-table a:hover {
    text-decoration: underline;
}

.error-message {
    color: #e63946;
    font-weight: bold;
    margin: 10px 0;
}
