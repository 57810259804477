/* Lead Capture Section */
.lead-capture-section {
    padding: 80px 20px;
    background: linear-gradient(to bottom right, #ffffff, #f5f5f5);
    text-align: center;
}

.lead-capture-container {
    max-width: 800px;
    margin: 0 auto;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 40px;
}

.lead-capture-text h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.lead-capture-text p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
}

.lead-capture-form .form-group {
    margin-bottom: 20px;
    text-align: left;
}

.lead-capture-form label {
    font-size: 1rem;
    font-weight: bold;
    color: #555;
    display: block;
    margin-bottom: 5px;
}

.lead-capture-form input,
.lead-capture-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
    box-sizing: border-box;
}

.lead-capture-form input:focus,
.lead-capture-form textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
}

.lead-capture-form button {
    background: #007bff;
    color: white;
    padding: 15px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    width: 100%;
}

.lead-capture-form button:hover {
    background: #0056b3;
    transform: translateY(-3px);
}

.form-status {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
}

.form-status.success {
    background: #28a745;
    color: white;
}

.form-status.error {
    background: #dc3545;
    color: white;
}
