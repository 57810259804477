/* Pricing Section */
.pricing-section {
    padding: 80px 20px;
    background: #f5f5f5;
    text-align: center;
}

.pricing-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 25px;
    color: #333;
}

.pricing-intro {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 50px;
    max-width: 700px;
    margin: 0 auto;
}

/* Pricing Cards */
.pricing-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
    gap: 30px; /* Spacing between cards */
}

.pricing-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
    overflow: hidden;
}

.pricing-card:hover {
    transform: translateY(-10px); /* Pop-up effect on hover */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.card-header {
    padding: 20px;
    color: white;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
}

.card-header.basic {
    background: #007bff; /* Blue for Basic */
}

.card-header.mid-tier {
    background: #ffb84d; /* Amber for Mid-Tier */
}

.card-header.premium {
    background: #28a745; /* Green for Premium */
}

.card-body {
    padding: 20px;
}

.price {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.price span {
    font-size: 1rem;
    color: #555;
}

.features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.features li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    color: #555;
    font-size: 1rem;
}

.features li::before {
    content: "★";
    position: absolute;
    left: 0;
    color: #ffb84d; /* Gold stars */
    font-size: 1.2rem;
}

/* Custom Pricing Section */
.custom-pricing {
    margin-top: 50px;
    font-size: 1.2rem;
    color: #555;
}

.custom-pricing strong {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

.custom-pricing strong:hover {
    color: #0056b3;
}

/* Responsive Design */
@media (max-width: 600px) {
    .pricing-cards {
        grid-template-columns: 1fr; /* Single column for small screens */
    }

    .pricing-card {
        text-align: center;
    }

    .price {
        font-size: 1.5rem;
    }
}

.contact-link {
    color: #007bff; /* Link color */
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
}

.contact-link:hover {
    color: #0056b3; /* Darker blue on hover */
}
