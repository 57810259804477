/* Main Page Container */
.notifications-page {
    padding: 20px;
    background: #fdfdfd;
    color: #333;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
}

body.dark-mode .notifications-page {
    background: #121212;
    color: #e0e0e0;
}

/* Section Styling */
.notifications-section {
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

body.dark-mode .notifications-section {
    background: #1e1e1e;
    box-shadow: none;
}

.notifications-section h2 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #4a90e2;
}

body.dark-mode .notifications-section h2 {
    color: #4a90e2;
}

/* Notification Item */
.notification-item {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9;
    transition: all 0.3s ease;
}

body.dark-mode .notification-item {
    background: #2c2c2c;
    border: 1px solid #444;
}

.notification-item.unread {
    border-left: 4px solid #4a90e2;
}

.notification-item.read {
    opacity: 0.7;
}

.notification-message {
    font-size: 1rem;
    flex: 1;
}

.notification-timestamp {
    font-size: 0.85rem;
    color: #666;
    margin-right: 15px;
}

body.dark-mode .notification-timestamp {
    color: #aaa;
}

/* Buttons */
.notification-item button {
    padding: 5px 10px;
    background: #4a90e2;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.notification-item button:hover {
    background: #357abd;
}

/* Empty State */
.empty-state {
    color: #aaa;
    font-style: italic;
    text-align: center;
}
