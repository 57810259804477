/* Main Export Page Styling */
.export-page {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fdfdfd;
    color: #333;
}

body.dark-mode .export-page {
    background-color: #121212;
    color: #e0e0e0;
}

/* Page Title */
h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #4a90e2;
    font-size: 1.8rem;
}

/* Filters Section */
.filters-section {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
    padding: 15px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

body.dark-mode .filters-section {
    background: #1e1e1e;
    box-shadow: none;
}

.filters-section label {
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #555;
}

body.dark-mode .filters-section label {
    color: #bbb;
}

.filters-section input,
.filters-section select {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f9f9f9;
    color: #333;
    transition: all 0.3s ease;
}

body.dark-mode .filters-section input,
body.dark-mode .filters-section select {
    background: #2c2c2c;
    color: #e0e0e0;
    border: 1px solid #444;
}

.filters-section input:focus,
.filters-section select:focus {
    border-color: #4a90e2;
    box-shadow: 0 0 5px rgba(74, 144, 226, 0.3);
}

/* Documents Table */
.documents-table {
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

body.dark-mode .documents-table {
    box-shadow: none;
}

.documents-table table {
    width: 100%;
    border-collapse: collapse;
    background: #ffffff;
}

body.dark-mode .documents-table table {
    background: #1e1e1e;
}

.documents-table th,
.documents-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
}

body.dark-mode .documents-table th,
body.dark-mode .documents-table td {
    border-bottom: 1px solid #444;
}

.documents-table th {
    background-color: #f7f7f7;
    font-weight: bold;
    color: #333;
}

body.dark-mode .documents-table th {
    background-color: #2c2c2c;
    color: #e0e0e0;
}

.documents-table tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

body.dark-mode .documents-table tr:hover {
    background-color: #2a2a2a;
}

/* Export Options */
.export-options {
    margin-top: 20px;
    padding: 15px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

body.dark-mode .export-options {
    background: #1e1e1e;
    box-shadow: none;
}

.export-options label {
    font-size: 14px;
    color: #555;
}

body.dark-mode .export-options label {
    color: #bbb;
}

.export-options select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f9f9f9;
    color: #333;
}

body.dark-mode .export-options select {
    background: #2c2c2c;
    color: #e0e0e0;
    border: 1px solid #444;
}

/* Buttons */
button {
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover:not(:disabled) {
    background-color: #357abd;
    transform: translateY(-2px);
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Export Message */
.export-message {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: #4caf50;
}

body.dark-mode .export-message {
    color: #3eaf3c;
}

/* Error Message */
.error-message {
    color: #e63946;
    font-weight: bold;
    text-align: center;
}

/* Table Responsiveness */
@media (max-width: 768px) {
    .documents-table table,
    .documents-table th,
    .documents-table td {
        font-size: 12px;
        padding: 8px;
    }

    .export-options {
        flex-direction: column;
        gap: 10px;
    }
}
