/* Why Go Digital Section */
.why-go-digital-card {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    padding: 25px;
    transition: transform 0.3s ease;
    text-align: center;
}

.why-go-digital-card:hover {
    transform: scale(1.03);
}

.digital-title {
    font-size: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

.digital-description {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}

.digital-stats {
    list-style-type: none;
    padding: 0;
}

.digital-stats li {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
    position: relative;
    padding-left: 30px;
}

.digital-stats li::before {
    content: "✔";
    font-size: 18px;
    color: #28a745;
    position: absolute;
    left: 0;
    top: 0;
}

.cta-button {
    display: inline-block;
    padding: 12px 20px;
    background: linear-gradient(90deg, #ffb84d, #ff944d);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s;
}

.cta-button:hover {
    background: linear-gradient(90deg, #ff944d, #ff7a4d);
}
