/* Savings Calculator Section */
.savings-calculator-section {
    padding: 60px 20px;
    background: #ffffff;
}

.calculator {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;
}

.calculator:hover {
    transform: scale(1.02);
}

.calculator h2 {
    font-size: 28px;
    font-weight: bold;
    color: #333;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #333;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: border-color 0.3s;
}

.form-group input:hover,
.form-group select:hover {
    border-color: #007bff;
}

.result {
    margin-top: 30px;
    padding: 20px;
    background: #f0f8f0;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.result h3 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.result p {
    font-size: 30px;
    font-weight: bold;
    color: #28a745;
}
