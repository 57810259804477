/* Main Container for Login Page */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #fdfdfd, #f7f7f7); /* Light gradient background */
    color: #333; /* Dark text for contrast */
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 20px;
    position: relative; /* For any decorative elements */
}

/* Logo on the login page */
.login-logo {
    display: block;
    margin-bottom: 20px; /* Add spacing below logo */
    max-width: 180px; /* Scaled logo size */
    height: auto; /* Maintain aspect ratio */
    animation: fadeIn 1s ease-in-out; /* Smooth fade-in animation */
}

/* Login Form */
.login-container form {
    background: #ffffff; /* Clean white card background */
    border-radius: 15px;
    padding: 30px 40px;
    width: 100%;
    max-width: 400px; /* Limit form width */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    text-align: center;
    position: relative; /* For inner elements like buttons */
    animation: slideUp 0.8s ease-in-out; /* Smooth slide-in effect */
}

/* Form Inputs */
.login-container input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    background: #f7f7f7; /* Soft background for inputs */
    border: 1px solid #ddd; /* Light border */
    border-radius: 10px;
    font-size: 1rem;
    color: #333;
}

.login-container input::placeholder {
    color: #aaa; /* Muted placeholder text color */
}

.login-container input:focus {
    outline: none;
    border: 2px solid #ffb84d; /* Vibrant border on focus */
    box-shadow: 0 0 5px rgba(255, 184, 77, 0.5); /* Glow effect */
}

/* Submit Button */
.login-container button {
    width: 100%;
    background: #ffb84d; /* Vibrant button color */
    color: #ffffff; /* White text for contrast */
    border: none;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 3px 10px rgba(255, 184, 77, 0.3); /* Subtle shadow */
}

.login-container button:hover {
    background: #e69a2e; /* Darker hover color */
    box-shadow: 0 5px 15px rgba(230, 154, 46, 0.5); /* Stronger shadow */
}

.login-container button:disabled {
    background: #ccc; /* Disabled state color */
    cursor: not-allowed;
}

/* Error Message */
.error-message {
    margin-top: 10px;
    color: #ff6b6b; /* Vibrant red for errors */
    font-size: 0.9rem;
    animation: shake 0.5s ease-in-out; /* Shake animation for errors */
}

/* Links (Forgot Password and Sign-Up) */
.login-container a {
    display: inline-block;
    color: #4a90e2; /* Accent blue for links */
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 10px;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.login-container a:hover {
    color: #003d99; /* Darker blue on hover */
    text-decoration: underline;
}

/* Forgot Password and Remember Me Section */
.form-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.form-options .remember-me {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
    color: #555; /* Muted text */
}

.form-options .forgot-password {
    font-size: 0.9rem;
    text-align: right;
}

/* Decorative Background Elements */
.login-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(135deg, #ffb84d, #ffffff); /* Decorative gradient */
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    75% {
        transform: translateX(5px);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .login-container form {
        padding: 20px;
    }

    .form-options {
        flex-direction: column;
        align-items: flex-start;
    }

    .form-options .forgot-password {
        margin-top: 10px;
    }
}
