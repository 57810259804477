/* How It Works Section */
.how-it-works-section {
    padding: 80px 20px;
    background: linear-gradient(to bottom right, #f5f5f5, #ffffff); /* Subtle gradient */
    color: #333;
    text-align: center;
}

.how-it-works-content {
    max-width: 1200px;
    margin: 0 auto;
}

.how-it-works-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.how-it-works-description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
    line-height: 1.8;
}

/* Steps Container */
.how-it-works-steps {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px;
}

/* Step Card */
.how-it-works-step {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

.how-it-works-step:hover {
    transform: translateY(-10px); /* Pop-up effect on hover */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.how-it-works-image {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
}

.how-it-works-step h3 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
}

.how-it-works-step p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
    .how-it-works-title {
        font-size: 2rem;
    }

    .how-it-works-description {
        font-size: 1rem;
    }

    .how-it-works-step h3 {
        font-size: 1.2rem;
    }

    .how-it-works-step p {
        font-size: 0.9rem;
    }
}
