.page-container {
    padding: 40px 20px; /* Consistent padding for all pages */
    max-width: 800px; /* Limit the width for readability */
    margin: 0 auto; /* Center the content */
    background-color: #ffffff; /* White background for clean appearance */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
    border-radius: 8px; /* Rounded corners */
}

.page-container h1 {
    font-size: 32px; /* Large, readable heading */
    font-weight: 700;
    margin-bottom: 20px;
    color: #1c1e2b; /* Dark purple for the heading */
    text-align: center; /* Center-align the main heading */
}

.page-container p {
    font-size: 16px; /* Readable paragraph font size */
    line-height: 1.6; /* Better readability with line height */
    color: #555; /* Gray color for paragraphs */
    margin-bottom: 20px; /* Space between paragraphs */
}

.page-container ul {
    list-style-type: disc; /* Use bullet points for lists */
    padding-left: 20px; /* Indent lists */
    margin-bottom: 20px;
}

.page-container ul li {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px; /* Space between list items */
}

.page-container a {
    color: #007bff; /* Link color */
    text-decoration: none; /* Remove underline */
    font-weight: bold;
    transition: color 0.3s ease; /* Smooth color change */
}

.page-container a:hover {
    color: #0056b3; /* Darker blue on hover */
}
