/* Hero Section Styles */
.hero {
    position: relative;
    background: linear-gradient(to bottom right, #ffb84d, #f5f5f5); /* Amber and white gradient */
    color: #333;
    text-align: center;
    padding: 100px 20px; /* Top and bottom spacing for prominence */
    overflow: hidden;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5); /* Transparent white overlay for text clarity */
    z-index: 1;
}

.hero-content {
    position: relative;
    z-index: 2; /* Above the overlay */
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align all content */
    justify-content: center;
}

.hero-title {
    font-size: 3rem; /* Large and bold title */
    font-weight: 700;
    margin-bottom: 20px;
    color: #333;
    text-align: center; /* Center text */
}

.hero-subtitle {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 30px;
    color: #555;
    text-align: center; /* Center text */
}

.consultation-button {
    background-color: #333;
    color: #fff;
    padding: 15px 30px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: transform 0.3s ease, background-color 0.3s ease;
    text-align: center; /* Ensure button text is centered */
    margin-top: 20px; /* Space above the button */
    margin-bottom: 20px; /* Space below the button */
}

.consultation-button:hover {
    background-color: #555;
    transform: translateY(-5px); /* Adds a hover pop effect */
}


/* Responsive Design */
@media (max-width: 768px) {
    .hero-title {
        font-size: 2.5rem;
    }

    .hero-subtitle {
        font-size: 1rem;
    }

    .consultation-button {
        font-size: 0.9rem;
        padding: 10px 30px;
    }
}
