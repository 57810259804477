/* Page Container */
.register-page {
    display: flex;
    height: 100vh; /* Full viewport height */
    background: linear-gradient(135deg, #fdfdfd, #f5f5f5); /* Light gradient */
    color: #333; /* Dark text for readability */
    font-family: 'Arial', sans-serif;
}

/* Left Section (Image or Slogan) */
.register-page .left-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 20px;
    background: url('https://source.unsplash.com/random/800x600') center/cover no-repeat; /* Replace with your image */
    color: #333; /* Dark text */
    box-shadow: inset -5px 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
}

.register-page .left-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.register-page .left-section p {
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.5;
    color: #555; /* Softer dark text */
}

/* Right Section (Form) */
.register-page .right-section {
    flex: 1;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 40px 20px;
    background: #ffffff; /* Card-like background */
    box-shadow: inset 5px 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
}

/* Form Styling */
.register-page form {
    background: #f8f8f8; /* Light card background */
    border-radius: 15px;
    padding: 30px;
    width: 100%;
    max-width: 400px; /* Limit form width */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

.register-page form h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center;
    color: #ffb84d; /* Vibrant accent color */
}

/* Form Inputs */
.register-page input,
.register-page select,
.register-page textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    background: #ffffff; /* Input background */
    border: 1px solid #ddd; /* Light border */
    border-radius: 10px;
    font-size: 1rem;
    color: #333; /* Dark text */
}

.register-page input::placeholder,
.register-page select,
.register-page textarea::placeholder {
    color: #aaa; /* Muted text for placeholders */
}

.register-page input:focus,
.register-page select:focus,
.register-page textarea:focus {
    outline: none;
    border: 2px solid #ffb84d; /* Vibrant border for focus */
}

/* Checkbox */
.register-page label {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 20px;
}

.register-page label input {
    margin-right: 10px;
}

/* Submit Button */
.register-page button {
    width: 100%;
    background: #ffb84d; /* Vibrant button color */
    color: #ffffff; /* White text */
    border: none;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 3px 10px rgba(255, 140, 66, 0.3); /* Subtle shadow */
}

.register-page button:hover {
    background: #ffb84d; /* Darker hover color */
    box-shadow: 0 5px 15px rgba(230, 122, 54, 0.5); /* Stronger shadow */
}

/* Link Styles */
.register-page .link {
    text-align: center;
    font-size: 0.9rem;
    color: #666; /* Muted dark color */
    margin-top: 10px;
}

.register-page .link a {
    color: #ffb84d; /* Vibrant link color */
    text-decoration: none;
}

.register-page .link a:hover {
    text-decoration: underline;
    color: #ffb84d; /* Darker hover color */
}
