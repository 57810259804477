.footer {
    background-color: #ffffff; /* Dark purple */
    color: #000; /* White text */
    padding: 20px 40px; /* Spacing inside the footer */
    text-align: center; /* Center-align the content */
    border-top: 1px solid #333; /* Optional border for separation */
    flex-shrink: 0; /* Prevent the footer from shrinking */
    height: auto; /* Ensure the height adjusts based on content */
}

.footer-content {
    max-width: 1200px; /* Limit width for larger screens */
    margin: 0 auto; /* Center the content */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Space between elements */
}

.footer-links {
    display: flex; /* Arrange links inline */
    gap: 20px; /* Space between links */
    justify-content: center; /* Center links horizontally */
}

.footer-links a {
    color: #ffb84d; /* Golden yellow */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease; /* Smooth hover effect */
}

.footer-links a:hover {
    color: #e69a2e; /* Slightly darker yellow on hover */
}
