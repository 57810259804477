/* Main Tag Management Page */
.tag-management-page {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background: #fdfdfd;
    color: #333;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #4a90e2;
    font-weight: bold;
}

/* Search and Add Tags Section */
.tag-controls {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    background: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.tag-controls input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.add-tag {
    display: flex;
    gap: 10px;
}

.add-tag input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.add-tag button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-tag button:hover {
    background-color: #3e8e41;
}

/* Buttons for Edit and Delete */
.tags-table button {
    display: flex;
    align-items: center;
    gap: 5px; /* Space between icon and text */
    background-color: #4a90e2;
    color: #fff;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
}

.tags-table button.edit {
    background-color: #f0ad4e; /* Orange for Edit */
}

.tags-table button.edit:hover {
    background-color: #ec971f;
}

.tags-table button.delete {
    background-color: #d9534f; /* Red for Delete */
}

.tags-table button.delete:hover {
    background-color: #c9302c;
}

/* Adjust alignment for icons */
.tags-table button svg {
    font-size: 1rem; /* Size of the FontAwesome icons */
}


/* No Tags Found */
.no-tags {
    text-align: center;
    color: #aaa;
    font-style: italic;
    margin-top: 20px;
}
