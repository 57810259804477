/* SideBySideCards.css */
.side-by-side-cards {
    padding: 50px 20px;
    background-color: #ffffff;
}

.card-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    gap: 20px; /* Add spacing between cards */
    justify-content: center; /* Center the cards */
    align-items: flex-start; /* Align items to the top */
    background-color: #ffffff;
}

/* Shared card styles */
.savings-calculator-card,
.why-go-digital-card {
    flex: 1; /* Allow equal space distribution */
    min-width: 300px; /* Minimum width for smaller screens */
    max-width: 500px; /* Maximum width to limit card size */
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Add hover effect */
.savings-calculator-card:hover,
.why-go-digital-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Headings and text styles for cards */
.savings-calculator-card h2,
.why-go-digital-card h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
}

.savings-calculator-card p,
.why-go-digital-card p {
    color: #555;
    font-size: 16px;
    line-height: 1.6;
}
