/* Sidebar container */
.sidebar {
    width: 260px; /* Slightly wider for better spacing */
    height: 100vh; /* Full height */
    background: linear-gradient(135deg, #ffffff, #f7f7f7); /* Light gradient */
    color: #333; /* Dark text for readability */
    padding: 20px;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1); /* Softer shadow for modern look */
    overflow-y: auto; /* Scrollable content */
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: all 0.3s ease;
    font-family: 'Arimo', sans-serif;
}

/* Hover effects for sidebar */
.sidebar:hover {
    width: 280px; /* Slight expansion */
    box-shadow: 4px 0 15px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

/* Logo container */
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

/* Logo image */
.logo {
    max-width: 70%;
    height: auto;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    transition: transform 0.3s ease;
}

.logo:hover {
    transform: scale(1.1); /* Slight zoom */
}

/* Sidebar title */
.sidebar h2 {
    font-size: 1.4rem;
    font-weight: bold;
    color: #ffb84d; /* Accent color */
    margin-bottom: 10px;
    text-align: center;
    letter-spacing: 0.5px;
}

/* Horizontal rule */
.sidebar hr {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

/* Sidebar menu list */
.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Menu items */
.sidebar ul li {
    margin: 0;
}

/* Links */
.sidebar ul li a {
    color: #333; /* Dark text */
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 12px 15px;
    border-radius: 8px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center; /* Vertical alignment */
    gap: 10px; /* Space for potential icons */
    background: #ffffff; /* Clean background for hover effects */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05); /* Subtle depth */
}

/* Hover effects for links */
.sidebar ul li a:hover {
    background: rgba(255, 184, 77, 0.2); /* Light amber background */
    color: #ffb84d; /* Accent color */
    transform: translateX(10px); /* Slide effect */
    box-shadow: inset 5px 0 0 rgba(255, 184, 77, 0.5); /* Left border highlight */
}

/* Active link */
.sidebar ul li a.active {
    background: rgba(255, 184, 77, 0.4); /* Brighter amber for active link */
    color: #ffffff; /* White text */
    box-shadow: inset 5px 0 0 #ffb84d; /* Bold left border */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .sidebar {
        width: 100%; /* Full-width sidebar for small screens */
        height: auto;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        display: none; /* Initially hidden */
    }

    .sidebar.open {
        display: flex; /* Show sidebar on toggle */
    }

    .sidebar ul li a {
        font-size: 1rem; /* Adjust text size for smaller screens */
    }
}

/* Sidebar Active Item */
.sidebar .menu-item.active {
    background: #eaeff5; /* Light highlight */
    border-left: 4px solid #4a90e2; /* Accent color */
    color: #4a90e2;
}
