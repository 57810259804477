/* Main Container */
.analytics-reports-page {
    padding: 20px;
    background: #fdfdfd;
    color: #333;
}

body.dark-mode .analytics-reports-page {
    background: #121212;
    color: #e0e0e0;
}

/* Chart Containers */
.chart-container {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

body.dark-mode .chart-container {
    background: #1e1e1e;
    box-shadow: none;
}

.chart-container h2 {
    font-size: 1.2rem;
    color: #4a90e2;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Word Cloud */
.word-cloud {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.word-cloud .word {
    font-size: 1rem;
    background: #4a90e2;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}

/* Status List */
.status-list {
    list-style: none;
    padding: 0;
}

.status-item {
    font-size: 1rem;
    margin-bottom: 5px;
}

/* Summaries Section */
.summaries {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.summary-card {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    flex: 1 1 calc(33.333% - 20px);
    display: flex;
    align-items: center;
    gap: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

body.dark-mode .summary-card {
    background: #1e1e1e;
    box-shadow: none;
}

.summary-icon {
    font-size: 2rem;
    color: #4a90e2;
}

.summary-content h3 {
    font-size: 1rem;
    margin: 0;
}

.summary-content p {
    font-size: 0.9rem;
    color: #666;
}
