/* About Page */
.about-page-container {
    font-family: "Inter", Arial, sans-serif;
    line-height: 1.8;
    color: #333;
    background: linear-gradient(to bottom right, #f5f5f5, #ffffff); /* Light gradient */
    padding: 20px;
}

/* Hero Section */
.about-hero {
    text-align: center;
    padding: 60px 20px;
    background: linear-gradient(to right, #ffb84d, #fce4b4); /* Warm gradient */
    color: #333;
    border-radius: 10px;
    margin-bottom: 40px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.about-hero h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.about-hero p {
    font-size: 1.2rem;
    max-width: 700px;
    margin: 0 auto;
}

/* Meet the Founder Section */
.about-founder-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 40px;
    margin: 40px 0;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.founder-photo-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

.founder-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid #ffb84d; /* Accent border */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.founder-details {
    flex: 2;
    text-align: left;
    font-size: 1.1rem;
}

.founder-details p {
    margin: 0;
    color: #555;
    line-height: 1.8;
}

/* Mission Section */
.mission-section {
    text-align: center;
    padding: 40px 20px;
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}

.mission-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.mission-section p {
    max-width: 700px;
    margin: 0 auto;
    font-size: 1.2rem;
    color: #555;
}

/* CTA Section */
.cta-section {
    text-align: center;
    padding: 40px 20px;
    background: linear-gradient(to bottom right, #007bff, #0056b3); /* Cool gradient */
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.cta-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: bold;
}

.cta-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.cta-button {
    display: inline-block; /* Ensures the button behaves as an inline element */
    margin: 0 auto; /* Centers the button horizontally */
    background: #ffb84d;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease;
}

.cta-button:hover {
    transform: translateY(-5px);
    background: #e69a2e;
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-founder-section {
        flex-direction: column;
        text-align: center;
    }

    .founder-details {
        text-align: center;
    }
}
