/* Page Container */
.upload-page {
    padding: 40px 20px;
    max-width: 900px; /* Increased width for better spacing */
    margin: 0 auto;
    background: linear-gradient(135deg, #fdfdfd, #f7f7f7); /* Light theme gradient */
    border-radius: 12px;
    color: #333; /* Dark text for contrast */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* Softer shadow */
    font-family: 'Arial', sans-serif;
}

/* Page Heading */
.upload-page h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #ffb84d; /* Vibrant accent color */
    font-size: 2rem;
    font-weight: bold;
}

/* Upload Method Section */
.upload-method {
    display: flex;
    justify-content: center;
    gap: 30px; /* More space between options */
    margin-bottom: 30px;
}

.upload-method label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    font-size: 1rem;
}

.upload-method input[type="radio"] {
    margin-right: 10px;
    accent-color: #ffb84d; /* Accent color for the radio button */
}

/* Drag-and-Drop Area */
.drag-drop-area {
    border: 2px dashed #ccc; /* Subtle border for drag area */
    padding: 30px;
    text-align: center;
    border-radius: 12px;
    background-color: #ffffff; /* Clean background */
    color: #555; /* Neutral text color */
    transition: background-color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 1.1rem;
    font-weight: bold;
}

.drag-drop-area:hover {
    border-color: #ffb84d; /* Accent color on hover */
    background-color: #fdf4e3; /* Light amber background */
}

.drag-drop-area.active {
    border-color: #ffb84d;
    background-color: #fff6d1; /* Highlighted background for active state */
}

/* File Preview Section */
.file-preview {
    margin-top: 20px;
    padding: 15px;
    background: #ffffff; /* Light background for contrast */
    border-radius: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.file-preview h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #ffb84d; /* Accent color for subheading */
    font-weight: bold;
}

.file-preview ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.file-preview li {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    color: #333;
}

.file-preview li:last-child {
    border-bottom: none;
}

/* Upload Button */
button {
    display: block;
    margin: 30px auto 0;
    background: #ffb84d; /* Accent color */
    color: #ffffff;
    border: none;
    padding: 15px 25px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 12px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
    box-shadow: 0 3px 10px rgba(255, 184, 77, 0.3);
}

button:disabled {
    background: #ccc; /* Neutral background for disabled state */
    cursor: not-allowed;
    box-shadow: none;
}

button:hover:not(:disabled) {
    background: #e69a2e; /* Darker accent color */
    transform: translateY(-2px);
}

/* Upload Progress Bar */
.upload-progress {
    margin-top: 20px;
    text-align: center;
}

.upload-progress progress {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    appearance: none;
}

.upload-progress progress::-webkit-progress-bar {
    background: #f7f7f7; /* Light background for bar */
    border-radius: 5px;
}

.upload-progress progress::-webkit-progress-value {
    background: #ffb84d; /* Accent color */
    border-radius: 5px;
}

.upload-progress span {
    display: block;
    margin-top: 10px;
    color: #555;
    font-size: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .upload-page {
        padding: 20px;
        max-width: 100%;
    }

    .upload-method {
        flex-direction: column;
        gap: 15px;
    }

    button {
        width: 100%;
    }
}
