/* Page Container */
.integration-settings {
    padding: 40px;
    max-width: 900px;
    margin: 0 auto;
    background: linear-gradient(135deg, #fdfdfd, #f7f7f7); /* Light theme gradient */
    border-radius: 12px;
    color: #333; /* Dark text */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

/* Page Heading */
.integration-settings h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #ffb84d; /* Accent color */
    font-size: 2rem;
    font-weight: bold;
}

/* Section Styling */
.integration-settings section {
    margin-bottom: 40px;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
}

/* Section Headings */
.integration-settings h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #ffb84d; /* Accent color */
    font-weight: bold;
}

/* Description Text */
.integration-settings p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

/* Buttons */
.integration-options button,
.scanner-options button {
    margin: 5px;
    padding: 10px 20px;
    background: #ffb84d;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1rem;
    font-weight: bold;
}

.integration-options button:disabled,
.scanner-options button:disabled {
    background: #ddd; /* Neutral background for disabled state */
    color: #aaa; /* Muted text */
    cursor: not-allowed;
}

.integration-options button:hover:not(:disabled),
.scanner-options button:hover:not(:disabled) {
    background: #e69a2e;
    transform: translateY(-2px);
}

/* Connected Services and Scanners */
.connected-services ul,
.connected-scanners ul {
    list-style-type: none;
    padding: 0;
}

.connected-services li,
.connected-scanners li {
    margin: 10px 0;
    padding: 10px;
    background: #f7f7f7;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.connected-services li button,
.connected-scanners li button {
    padding: 6px 12px;
    font-size: 0.9rem;
    background: #ff6b6b;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.connected-services li button:hover,
.connected-scanners li button:hover {
    background: #e63946;
}
