/* DocumentDetailsPage.css */

.document-details-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

h1 {
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 20px;
}

.document-preview {
    margin-bottom: 30px;
    text-align: center;
}

.document-preview iframe,
.document-preview img {
    width: 100%;
    max-width: 800px;
    height: 500px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.document-metadata {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
}

.document-metadata h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.document-metadata p {
    margin: 5px 0;
}

.document-metadata strong {
    color: #555;
}

.editable-fields label {
    display: block;
    margin-bottom: 10px;
}

.editable-fields label input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.edit-buttons {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;
}

.edit-buttons button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.edit-buttons button:hover {
    opacity: 0.9;
}

.edit-buttons button:first-child {
    background-color: #4caf50;
    color: white;
}

.edit-buttons button:last-child {
    background-color: #f44336;
    color: white;
}

.extracted-data {
    margin-top: 30px;
}

.extracted-data h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.extracted-data pre {
    background-color: #f1f1f1;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow-x: auto;
    max-height: 300px;
}

.error {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

button {
    cursor: pointer;
    transition: background-color 0.3s ease;
}
