/* Settings Page */
.settings-page {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    color: #333;
}

body.dark-mode .settings-page {
    background-color: #121212;
    color: #e0e0e0;
}

/* Tabs */
.tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.tabs button {
    padding: 10px 20px;
    border: none;
    background-color: #4a90e2;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tabs button.active {
    background-color: #357abd;
}

.tabs button:hover {
    background-color: #357abd;
}

/* Tab Content */
.tab-content {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

body.dark-mode .tab-content {
    background-color: #1e1e1e;
    box-shadow: none;
}

/* Input Fields */
input[type="text"], input[type="file"] {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #fff;
    color: #333;
}

body.dark-mode input[type="text"], body.dark-mode input[type="file"] {
    background-color: #2c2c2c;
    color: #e0e0e0;
    border: 1px solid #444;
}

/* Buttons */
.save-button, .sign-out-button, .dark-mode-toggle {
    padding: 10px 20px;
    border: none;
    background-color: #4caf50;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-button:hover, .sign-out-button:hover, .dark-mode-toggle:hover {
    background-color: #45a049;
}

/* Profile Picture */
.profile-picture-container {
    text-align: center;
    margin: 20px 0;
}

.profile-picture {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #e0e0e0;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    color: #333;
    border: 2px solid #ccc;
    margin: 0 auto 10px auto;
}

.profile-picture input {
    display: none;
}

.profile-picture-container input[type="file"] {
    display: block;
    margin: 10px auto;
    font-size: 1rem;
}
