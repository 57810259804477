.audit-logs-page {
    font-family: Arial, sans-serif;
    padding: 20px;
}

.filters-section {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
}

.filter-group {
    display: flex;
    flex-direction: column;
    flex: 1 1 200px;
}

.filter-group label {
    font-weight: bold;
    margin-bottom: 5px;
}

.filter-buttons {
    display: flex;
    gap: 10px;
}

.logs-section {
    margin-top: 20px;
}

.logs-table {
    width: 100%;
    border-collapse: collapse;
}

.logs-table th,
.logs-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.logs-table th {
    background-color: #f4f4f4;
    text-align: left;
}

.error-message {
    color: red;
    font-weight: bold;
}
